[data-v-d2fc5c06] .el-col {
  margin-bottom: 8px !important;
}
[data-v-d2fc5c06] .avue-crud .el-table {
  height: calc(100vh - 320px) !important;
  max-height: calc(100vh - 320px) !important;
}
[data-v-d2fc5c06] .el-tabs__header {
  padding: 0 12px;
  margin: 0;
  background: #fff;
}